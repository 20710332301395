'use strict';

angular.module('enervexSalesappApp').factory('Quote', function ($resource) {
	return $resource('/api/accounts/:accountId/jobs/:jobId/quotes/:updateableVersions/:designId/:id/:generateLineitems/:versions/:version', {
		id: '@_id'
	},
	{
		update: {
			method: 'PUT'
		},
		generateLineitems: {
			method: 'POST',
			params: {
				accountId: '@accountId',
				jobId: '@jobId',
				generateLineitems: 'generate-lineitems'
			}
		},
		version: {
			method: 'GET',
			params: {
				accountId: '@accountId',
				jobId: '@jobId',
				id: '@quoteId',
				versions: 'versions',
				version: '@version'
			}
		},
		versions: {
			method: 'GET',
			isArray: true,
			params: {
				accountId: '@accountId',
				jobId: '@jobId',
				id: '@quoteId',
				versions: 'versions'
			}
		},
		hasUpdatableVersions: {
			method: 'GET',
			isArray: true,
			params: {
				accountId: '@accountId',
				jobId: '@jobId',
				designId: '@designId',
				version: '@version',
				updateableVersions:'updateable-versions'
			}
		},
		updateVersions: {
			method: 'POST',
			isArray: true,
			params: {
				accountId: '@accountId',
				jobId: '@jobId',
				designId: '@designId',
				version: '@version',
				updateableVersions:'updateable-versions'
			}
		}
	});
});
